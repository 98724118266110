<template>
  <nav class="gnb">
    <h1 class="logo" @click.prevent="home">
      <a href="#self">
        <span class="blind">OUND</span>
      </a>
    </h1>

    <button type="button" class="btn_close" @click="handleClose">
      <i class="icon_size_24 icon_font_del_02" /><span class="blind">닫기</span>
    </button>

    <ul>
      <li v-for="item in state.menuList" :key="item.type" class="dropdown" :class="{ on: item.isActive }">
        <a v-if="item.list.length > 0" href="#self" @click.prevent="open(item.type)">{{ item.name }}</a>
        <ul>
          <li v-for="item2 in item.list" :key="item2.id">
            <template v-if="item2.list && item2.list.length > 0">
              <span>{{ item2.name }}</span>
              <ul>
                <li v-for="item3 in item2.list" :key="item3.id">
                  <a href="#self" @click.prevent="handleClick(item3)">{{ item3.name }}</a>
                </li>
              </ul>
            </template>
            <template v-else>
              <a href="#self" @click.prevent="handleClick(item2)">{{ item2.name }}</a>
            </template>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { type IMenu, type IMenuItem } from '../types/menu'

const props = withDefaults(
  defineProps<{ list: IMenu[] }>(),
  { list: () => [] }
)

const emit = defineEmits<{(e: 'click', type: IMenuItem): void, (e: 'close'): void }>()

const route = useRoute()

const state = reactive<{ menuList: IMenu[] }>({ menuList: [] })

onMounted(() => {
  state.menuList = props.list
})

watch(() => props.list, (value) => {
  state.menuList = value
})

const open = (type: string) => {
  state.menuList = state.menuList.map((item: IMenu) => {
    if (item.type === type) {
      return {
        ...item,
        isActive: !item.isActive
      }
    }
    return item
  })
}

const home = () => {
  if (route.path === '/') {
    window.scrollTo({ top: 0 })
  } else {
    navigateTo('/')
  }
  emit('close')
}

const handleClose = () => {
  emit('close')
}

const handleClick = (menu: IMenuItem) => {
  emit('click', menu)
}

</script>

<style scoped>
.gnb {
  display: none;
  position: absolute;
  right: 0;
  top: 60px;
  width: 296px;
  background: var(--color-background-default);
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.1);
}

.gnb>ul>li>a {
  position: relative;
  display: block;
  padding: 0 36px 0 24px;
  background: var(--color-background-sub1);
  height: 3rem;
  line-height: 3rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--color-text-title);
}

.gnb>ul>li>ul>li {
  padding: 8px 0;
  border-bottom: 1px solid var(--color-background-sub1);
}

.gnb>ul>li>ul>li>span {
  display: block;
  padding: 0 24px;
  line-height: 2.125rem;
  height: 2.125rem;
  color: var(--color-text-default);
}

.gnb>ul>li>ul>li:last-child {
  border: none;
}

.gnb>ul>li>ul>li a {
  display: block;
  padding: 0 24px;
  line-height: 2.25rem;
  height: 2.25rem;
  color: var(--color-text-category);
}
 .gnb > ul > li > ul > li a:hover{color:var(--color-sub1);}

.gnb.on {
  display: block;
}

.gnb .logo{display:none;}
.gnb .btn_close{display:none;}

@media screen and (max-width: 768px) {
  .gnb{position: fixed; top:0; width:100%; height:calc(100%); z-index:1; overflow-y:auto; padding-bottom:10px; box-sizing:border-box;  box-shadow:none;}
  .gnb > ul > li > a:before{position:absolute; right:12px; top:calc(50% - 12px); display:flex; width:1.5rem; height:1.5rem; justify-content: center; align-items: center; content:'\e010'; color: var(--color-txt_title); font-size:7.65px; transform:rotate(-180deg);}

  .gnb>ul>li.on>a:before {
    transform: rotate(0deg);
  }

  .gnb>ul>li {
    border: 1px solid var(--color-background-default)
  }

  .gnb>ul>li:first-child {
    border-top: 1px solid var(--color-line);
    border-width: 1px 0 1px;
  }

  .gnb>ul>li>ul {
    height: 0;
    overflow: hidden;
    transition: all .7s ease;
  }

  .gnb>ul>li.on>ul {
    height: auto;
    transition: all .3s ease;
  }

  .gnb .logo{display:block; padding:19px 0; text-align:center;}
  .gnb .logo a {display:inline-block; width: 85.4px; height: 18px; }
  .gnb .logo a::after {content: ''; display: block; width: 85.4px; height: 18px; background: url(@/assets/images/common/logo.svg) 0 0 no-repeat;}
  .gnb .btn_close{display:inline-block; width:24px; position:absolute; right:15px; top:15px;}
  .gnb .btn_close .icon_font_del_02:before{font-size:13.18px;}
}
</style>
