import { type INavigatorItem } from '~/modules/layout/navigator'

export default function useNavigator () {
  const navList = ref<INavigatorItem[]>([
    { type: 'HOME', name: '홈', icon: 'icon_font_home' },
    { type: 'MENU', name: '메뉴', icon: 'icon_font_menu' },
    { type: 'SEARCH', name: '검색', icon: 'icon_font_search' },
    { type: 'BOOKMARK', name: '북마크', icon: 'icon_font_bookmark' },
    { type: 'MYPAGE', name: '마이페이지', icon: 'icon_font_member' }
  ])

  // 네비게이터 활성화 초기화
  const clearNavActive = () => {
    navList.value = navList.value.map((item: INavigatorItem) => {
      if (item.isActive) {
        return { ...item, isActive: false }
      }
      return item
    })
  }

  // 네비게이터 활성화
  const setNavActive = (path: string) => {
    clearNavActive()

    if (path === '/') {
      navList.value[0].isActive = true
    }

    if (path.includes('/interest')) {
      navList.value = addNavActiveType('BOOKMARK')
    }

    if (path.includes('/mypage')) {
      navList.value = addNavActiveType('MYPAGE')
    }

    if (path.includes('/menu')) {
      navList.value = addNavActiveType('MENU')
    }

    if (path.includes('/search')) {
      navList.value = addNavActiveType('SEARCH')
    }
  }

  // isActive 항목 true로 변경
  const addNavActiveType = (type: string) => {
    return navList.value.map((item: INavigatorItem) => {
      if (item.type === type) {
        return { ...item, isActive: true }
      }
      return item
    })
  }

  return {
    navList,
    setNavActive
  }
}
