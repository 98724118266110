<template>
  <div class="search_box">
    <div v-show="!isKeyword" class="search_list">
      <h2 class="font_h6">
        추천 검색어
      </h2>
      <div class="txt_list">
        <ul>
          <li v-for="keyword in recommendKeywordList" :key="keyword.tagSeq">
            <a href="#" @click.prevent="navigateToSearchContentPage(keyword.tagName)">
              {{ keyword.tagName }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div v-show="isKeyword" class="search_list_02">
      <div
        v-if="result.categoryList.length > 0 || result.interestList.length > 0 || result.businessList.length > 0"
        class="txt_list_02"
      >
        <ul v-if="result.categoryList.length > 0">
          <li v-for="category in result.categoryList" :key="category.categoryNo">
            <a href="#" @click.prevent="navigateToSearchContentPage(category.categoryName)">
              <span class="cate" v-html="`${setKeywordHighlight(category.categoryName)}`" />
              <span class="tag">카테고리</span>
            </a>
          </li>
        </ul>
        <ul v-if="result.interestList.length > 0">
          <li v-for="interest in result.interestList" :key="interest.comCd">
            <a href="#" @click.prevent="navigateToSearchContentPage(interest.codeName)">
              <span class="cate" v-html="`${setKeywordHighlight(interest.codeName)}`" />
              <span class="tag">관심분야</span>
            </a>
          </li>
        </ul>
        <ul v-if="result.businessList.length > 0">
          <li v-for="business in result.businessList" :key="business.businessSeq">
            <a href="#" @click.prevent="navigateToSearchBussinessPage(business.companyName)">
              <span class="cate" v-html="`${setKeywordHighlight(business.companyName)}`" />
              <span class="tag">기업</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="txt_list_03">
        <ul>
          <li v-for="contents in result.contentsList" :key="contents.postSeq">
            <a href="#" @click.prevent="navigateToSearchContentPage(contents.title)">
              <span class="txt" v-html="`${setKeywordHighlight(contents.title)}`" />
            </a>
          </li>
          <li v-for="tag in result.tagList" :key="tag.tagSeq">
            <a href="#" @click.prevent="navigateToSearchContentPage(tag.tagName)">
              <span class="txt" v-html="`# ${setKeywordHighlight(tag.tagName)}`" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { StringUtil } from '@tms/utils'

import type { IKeywordBusiness, IKeywordCategory, IKeywordContents, IKeywordInterest, IKeywordTag } from '~/modules/content/types/model'
import useContentNavigator from '~/modules/content/composables/navigate'
import useContentSearch from '~/modules/content/composables/search'
import { SEARCH_AFFECT_TYPE } from '~/modules/content/types/type'

const props = defineProps<{ keyword: string }>()
const emit = defineEmits<{(e: 'keyword', item: string): void, (e: 'close'): void }>()

const { navigateToSearchBussiness, navigateToSearchContents } = useContentNavigator()
const { getSearchKeywordList, getRecommendKeywordList, setHighlightKeyword } = useContentSearch()

const state = reactive({ keyword: '' })
const recommendKeywordList = ref<IKeywordTag[]>([])
const result = reactive<{ categoryList: IKeywordCategory[], interestList: IKeywordInterest[], businessList: IKeywordBusiness[], tagList: IKeywordTag[], contentsList: IKeywordContents[], }>({ categoryList: [], interestList: [], businessList: [], tagList: [], contentsList: [] })

const isKeyword = computed(() => state.keyword.trim().length > 0)

onBeforeMount(async () => {
  await initRecommendKeywordList()
})

watch(() => props.keyword, (data) => {
  state.keyword = data

  const keyword = state.keyword.trim()
  if (StringUtil.isEmpty(keyword)) {
    result.categoryList = []
    result.interestList = []
    result.businessList = []
    result.tagList = []
    result.contentsList = []
  } else {
    getSearchKeywordList(state.keyword.trim())
      .then((response: any) => {
        result.categoryList = response.categoryList
        result.interestList = response.interestList
        result.businessList = response.businessList
        result.tagList = response.tagList
        result.contentsList = response.contentsList
      })
      .catch((err: any) => {
        console.log('err > ', err)
      })
  }
})

const initRecommendKeywordList = async () => {
  const response = await getRecommendKeywordList()
  recommendKeywordList.value = response.keyword
}

/**
 * 입력한 검색어 기준으로 하이라이트 처리를 합니다.
 * @param word 하이라이트 처리할 단어
 */
const setKeywordHighlight = (word: string) => {
  return setHighlightKeyword(word, state.keyword)
}

const navigateToSearchContentPage = (keyword: string) => {
  emit('keyword', keyword)
  emit('close')
  navigateToSearchContents(keyword, SEARCH_AFFECT_TYPE.Related)
}

const navigateToSearchBussinessPage = (keyword: string) => {
  emit('keyword', keyword)
  emit('close')
  navigateToSearchBussiness(keyword)
}

</script>

<style scoped>
.search_box{position:fixed; left:0; top:56px; width:100%; background:var(--color-background-default); min-height:374px; box-sizing:border-box; z-index:100; border-bottom: 1px solid var(--color-line);}
.search_list{width:749px; margin: 0 auto; padding:40px 0;}
.search_list .font_h6{color:var(--color-text-title);}
.search_list .txt_list{margin-top:12px; width: calc(100% + 8px);}
.search_list .txt_list li{display:inline-block;margin:0 8px 8px 0;}
.search_list .txt_list li a{display:inline-block; padding:0 8px; line-height:1.375rem; font-size:var(--font-size-8); color:var(--color-text-sub); background:var(--color-background-sub1); box-sizing:border-box;}
.search_list .txt_list li:last-child{margin:0;}

.search_list_02{width:752px; margin: 0 auto; padding:40px 0; display: flex; gap: 40px; box-sizing: border-box;}
.search_list_02 .txt_list_02{width: 262px;}
.search_list_02 ul{margin-top:24px;}
.search_list_02 ul:first-child{margin-top:0;}
.search_list_02 li{margin:0 0 8px 0;}
.search_list_02 li a{line-height:1.375rem; font-size:var(--font-size-8); color:var(--color-text-sub); display: flex; justify-content: space-between; align-items: center;}
.search_list_02 li a strong{color:var(--color-text-title);}
.search_list_02 li a .cate{display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; white-space: normal; word-break: break-all;}
.search_list_02 li a .tag{flex:none; margin-left:10px; display:inline-block; height:0.875rem; line-height:0.875rem; padding:0 4px; color:var(--color-text-default); background:var(--color-background-sub1); border-radius:4px;}
.search_list_02 .txt_list_03{width: calc(100% - 302px);}
.search_list_02 .txt_list_03 li .txt{display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; white-space: normal; word-break: break-all; width:100%;}

@media screen and (max-width: 768px) {
  .search_box{top:56px;}
  .search_list{width:calc(100% - 40px); padding:20px;}
  .search_list_02{width:100%; padding:20px; flex-direction: column; gap: 20px; height: calc(100vh - 180px); overflow-y:auto;}
  .search_list_02 .txt_list_02{width: 100%;}
  .search_list_02 ul{margin-top:20px;}
  .search_list_02 .txt_list_03{width: 100%;}
}
</style>
