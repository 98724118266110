<template>
  <header id="header">
    <div class="inner_box">
      <h1 class="logo">
        <a href="#" @click.prevent="home">
          <span class="blind">OUND</span>
        </a>
      </h1>

      <button type="button" class="btn_search_back" @click="handleClickSearchAreaBack">
        <i class="icon_size_24 icon_font_arrow" />
        <span class="blind">뒤로</span>
      </button>

      <button type="button" class="btn_back" @click="handleNavigateBack">
        <i class="icon_size_24 icon_font_arrow" />
        <span class="blind">뒤로</span>
      </button>

      <div class="util">
        <form-input-search
          ref="inputSearchRef"
          :class="{ search_on: state.isSearch }"
          :keyword="keyword"
          placeholder="카테고리, 관심 분야, 기업, 키워드"
          @search="handleSearch"
          @enable="handleEnabledSearch"
          @disable="handleDisabledSearch"
          @keyup="handleUpdateKeyword"
          @enter="handleSearch"
        />

        <button type="button" class="btn_util" @click="bookmark">
          <i class="icon_font_bookmark" />
          <span class="blind">북마크</span>
        </button>
        <button type="button" class="btn_util" @click="mypage">
          <i class="icon_font_member" />
          <span class="blind">마이페이지</span>
        </button>
        <button type="button" class="btn_util" @click="menu">
          <i class="icon_font_menu" />
          <span class="blind">메뉴</span>
        </button>
      </div>

      <client-only>
        <menu-list
          class="gnb_menu_list"
          :class="{ on: state.isCategory }"
          :list="state.menuList"
          @click="handleClickCategory"
          @close="handleCloseCategory"
        />
      </client-only>

      <div class="btn_close">
        <button type="button" @click="handleNavigateBack">
          <i class="icon_size_24 icon_font_del_02" /><span class="blind">닫기</span>
        </button>
      </div>
    </div>

    <div class="dropdown" :class="{ search_on: state.isSearch }">
      <the-search-Box
        :keyword="keyword"
        @keyword="handleUpdateKeyword"
        @close="handleCloseSearchBox"
      />
    </div>

    <navigator-list :list="navList" @click="handleClickNavigator" />
  </header>
</template>

<script setup lang="ts">
import { SigninAuth } from '@tms/authorization'
import path from '~/config/route'

import type { IMenu, IMenuItem } from '~/modules/layout/menu/types/menu'
import { MST_CATEGORY_TYPE } from '~/modules/globalCode/types/code'
import { useCodeStore } from '~/modules/globalCode/store/codeStore'
import { useCategoryCodeStore } from '~/modules/category/store/categoryStore'

import useNavigator from '~/composables/layouts/navigator'
import useCategory from '~/modules/category/composables/category'
import useContentNavigator from '~/modules/content/composables/navigate'
import usePopup from '~/modules/popup/modal/composables/popup'
import { useMyHomeStore } from '~/modules/member/mypage/home/store/myHomeStore'
import { SEARCH_AFFECT_TYPE } from '~/modules/content/types/type'

const auth = new SigninAuth()

const route = useRoute()
const codeStore = useCodeStore()
const categoryStore = useCategoryCodeStore()
const myHomeStore = useMyHomeStore()
const { navList, setNavActive } = useNavigator()
const { getCategoryListByType } = useCategory()
const { navigateToLogin } = useMemberNavigator()
const { navigateToCategoryPostList, navigateToSearchContents } = useContentNavigator()
const { addFixedUi, removeFixedUi } = usePopup()

const alert: any = inject('alert')
const confirm: any = inject('confirm')

const inputSearchRef = ref<any>(null)

const keyword = ref('')
const state = reactive<{
  isSearch: boolean,
  isSearchHeader: boolean,
  isCategory: boolean,
  menuList: IMenu[]
}>({
  isSearch: false,
  isSearchHeader: false,
  isCategory: false,
  menuList: []
})

onMounted(() => {
  setNavActive(route.path)
  initCategoryList()

  // 검색 결과 페이지인 경우 Search 제어
  initSearchHeader()
  // 외부 클릭시 닫힘 이벤트 등록
  document.addEventListener('click', clickOutside)
})

onUnmounted(() => {
  // 외부 클릭시 닫힘 이벤트 제거
  document.removeEventListener('click', clickOutside)
})

watch(() => route.fullPath, (path) => {
  setNavActive(path)
  clearToggle()

  // 검색 결과 페이지인 경우 Search 제어
  initSearchHeader()
})

watch(() => state.isSearchHeader, (isSearchHeader) => {
  const headerEl = document.querySelector('#header')

  if (isSearchHeader) {
    headerEl && headerEl.classList.add('search_on')
  } else {
    headerEl && headerEl.classList.remove('search_on')
  }

  if (isMobile()) {
    keyword.value = ''
  }
})

// Search Box && Category Menu 활성화 여부
watch(() => [state.isSearch, state.isCategory], ([isSearch, isCategory]) => {
  removeFixedUi()

  if (isMobile() && (isSearch || isCategory)) {
    addFixedUi()
  }

  // 카테고리 오픈 시 항목 모두 fold
  if (isCategory) {
    menuAllFold()
  }
})

// 검색 header 기본 설정
const initSearchHeader = () => {
  if (isSearchResultPage() && isMobile()) {
    setSearchHeader(true)
  } else {
    setSearchHeader(false)
  }
}

/** 검색 및 카테고리 팝업 닫기 */
const clearToggle = () => {
  // 검색 Layer 비 활성화
  inputSearchRef.value && inputSearchRef.value.disable()

  state.isSearch = false
  state.isCategory = false
  setNavActive(route.path)
}

const initCategoryList = () => {
  const categoryList = categoryStore.list
  const categoryTypeList = codeStore.list
    .find((item: any) => item.groupCd === MST_CATEGORY_TYPE)?.codeList

  state.menuList = categoryTypeList.map((code: any) => {
    return {
      type: code.comCd,
      name: code.codeName,
      list: getCategoryListByType(categoryList, code.comCd)
    }
  })
}

const handleUpdateKeyword = (searchKeyword: string) => {
  keyword.value = searchKeyword
}

const handleCloseSearchBox = () => {
  inputSearchRef.value && inputSearchRef.value.disable()
  state.isSearch = false
}

const menuAllFold = () => {
  state.menuList = state.menuList.map((item: IMenu) => {
    item.isActive = true
    return item
  })
}

// 모바일 여부 확인 ( true: 모바일, false: PC )
const isMobile = () => {
  const width = window.innerWidth
  return width <= 768
}

// 검색 결과 여부 확인 ( true: 검색 결과 페이지, false: 그 외 페이지 )
const isSearchResultPage = () => {
  return route.path.includes('/search')
}

// Search Box 활성화 / 비활성화
const setSearch = (isSearch: boolean) => {
  state.isSearch = isSearch
}

// Search Header 활성화 / 비활성화
const setSearchHeader = (isSearchHeader: boolean) => {
  state.isSearchHeader = isSearchHeader
}

// 검색 영역 뒤로가기 버튼 클릭
const handleClickSearchAreaBack = () => {
  if (isSearchResultPage()) {
    if (state.isSearch) {
      inputSearchRef.value && inputSearchRef.value.disable()
      setSearch(false)
    } else {
      handleNavigateBack()
    }
  } else {
    setSearchHeader(false)
    setSearch(false)
    setNavActive(route.path)
  }
}

// 검색 Input Focus
const handleEnabledSearch = () => {
  // PC
  if (!isMobile()) {
    setSearchHeader(true)
    setSearch(true)
  }

  // 모바일 검색 페이지
  if (isMobile() && isSearchResultPage()) {
    setSearch(true)
  }
}

// 검색 Input Focus Out
const handleDisabledSearch = () => {
  // PC
  if (!isMobile()) {
    setSearchHeader(false)
    setSearch(false)
  }
}

const handleSearch = () => {
  const trimedKeyword = keyword.value.trim()
  if (trimedKeyword.length < 1) {
    alert.open({ title: '검색어를 입력해주세요' })
    return
  }

  navigateToSearchContents(trimedKeyword, SEARCH_AFFECT_TYPE.Typing)
}

const handleClickCategory = (menuItem: IMenuItem) => {
  navigateToCategoryPostList(menuItem.id)
}

const handleCloseCategory = () => {
  state.isCategory = false
  setNavActive(route.path)
}

const handleNavigateBack = () => {
  const router = useRouter()
  const historyLength = window.history?.length ?? 1
  if (historyLength > 1) {
    router.back()
    return
  }
  handleClickNavigator('HOME')
}

const handleClickNavigator = (type: string) => {
  switch (type) {
    case 'HOME': home(); break
    case 'MENU': menuByMobile(); break
    case 'SEARCH': search(); break
    case 'BOOKMARK': bookmark(); break
    case 'MYPAGE': mypage(); break
  }
}

const home = () => {
  clearToggle()

  if (route.path === '/') {
    window.scrollTo({ top: 0 })
  } else {
    navigateTo('/')
  }
}

const menu = () => {
  const isCategory = state.isCategory
  clearToggle()

  if (isCategory) {
    state.isCategory = false
  } else {
    state.isCategory = true
  }
}

const menuByMobile = () => {
  navigateTo('/mobile/category')
}

// 모바일 Search Navigate
const search = () => {
  clearToggle()

  const isSearch = !state.isSearch
  if (!(isSearchResultPage() && isSearch)) {
    setSearchHeader(isSearch)
  }
  setSearch(isSearch)

  if (isSearch) {
    setNavActive('/search')
  } else {
    setNavActive(route.path)
  }
}

const bookmark = () => {
  clearToggle()

  checkedOpenLogin(() => {
    navigateTo(path.INTEREST)
  })
}

const mypage = () => {
  clearToggle()

  checkedOpenLogin(() => {
    if (myHomeStore.isBusiness) {
      navigateTo(path.MY_PAGE_BUSINESS)
    } else {
      navigateTo(path.MY_PAGE)
    }
  })
}

const checkedOpenLogin = (callback: Function) => {
  if (!auth.isSignin()) {
    confirm.open({
      title: '로그인이 필요합니다<br />로그인 페이지로 이동할까요?',
      confirm: () => {
        navigateToLogin()
      }
    })
  } else {
    callback && callback()
  }
}

/* 외부 클릭시 닫힘 */
const clickOutside = (e: MouseEvent) => {
  const target = e.target as HTMLElement
  // 검색 팝업 닫기
  if (state.isSearchHeader) {
    if (!target.closest('#header')) {
      clearToggle()
    }
  }

  // 카테고리 팝업 닫기
  if (state.isCategory) {
    if (!target.closest('.icon_font_menu') && !target.closest('.gnb_menu_list')) {
      clearToggle()
    }
  }
}

</script>

<style scoped>
#header {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid var(--color-line);
  background: var(--color-primary-0);
  box-sizing: border-box;
}

#header .inner_box {
  position: relative;
  width: 1200px;
  height: 60px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

#header .logo {
  display: inline-block;
}

#header .logo a {
  display: block;
  width: 85.4px;
  height: 18px;
}

#header .logo a::after {
  content: '';
  display: block;
  width: 85.4px;
  height: 18px;
  background: url(@/assets/images/common/logo.svg) 0 0 no-repeat;
}

#header .util {
  display: flex;
  width: calc(100% - 223px);
  align-items: center;
  position: absolute;
  right: 0;
  top: 13px;
  text-align: right;
  justify-content: flex-end;
}

#header .util .btn_util {
  display: inline-block;
  margin-left: 16px;
  width: auto;
}

#header.search_on .search_top {
  width: calc(100% - 104px);
}

#header .btn_back{margin-right:4px; display:none; position:fixed; left:14px; top:16px;}
#header .btn_search_back{margin-right:4px; display:none; position:fixed; left:14px; top:16px;}

#header .btn_close {
  display: none;
  height: 25px;
  position: absolute;
  right: -3px;
  top: 15px;
  text-align: right;
}

#header .btn_close button {
  display: inline-block;
}

#header .icon_font_del_02:before {
  font-size: 13.18px;
}

#header.simple .btn_close {
  display: block;
}

#header.simple .util .search_top {
  display: none;
}

#header.simple .util .btn_util {
  display: none;
}

#header .dropdown {
  opacity: 0;
  position: absolute;
  left: 0px;
  top: 60px;
  width: 100%;
  z-index: 103;
  height: 0;
  overflow: hidden;
  background: var(--color-primary-0);
}

#header .dropdown.search_on {
  transition: all .3s ease;
  opacity: 1;
  top: 108px;
  height: calc(100%);
}

/* 뒷 배경 클릭시 닫힘 처리를 위해 주석처리
#header .dropdown.search_on:before {
  content: '';
  display: block;
  position: fixed;
  left: 0px;
  top: 108px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  z-index: -1;
}
*/

#header .dropdown .search_box {
  display: none;
}

#header .dropdown.search_on .search_box {
  display: block;
}

@media screen and (max-width: 1280px) {
  #header .util {
    display: flex;
    width: calc(100% - 144px);
  }
}

@media screen and (max-width: 768px) {
  #header {
    height: 56px;
  }

  #header .inner_box {
    height: 56px;
    justify-content: center;
  }

  #header .util {
    width: calc(100% - 29px);
  }

  #header .util .search_top {
    display: none;
  }

  #header .util .btn_util {
    display: none;
  }

  #header.search_on {
    z-index: 101;
  }

  #header.search_on .search_top {
    display: flex;
    width: 100%;
  }

  #header.search_on .btn_search_back {
    display: block;
  }

  #header.simple{border:none;}
  #header.simple.search_on .util .search_top{display:flex;}
  #header.simple.search_on .btn_close{display:none;}

  #header.sub .btn_back{display:block;}
  #header.sub .logo{display:none;}

  #header.mypage .logo{display:none;}

  #header.detail .btn_back{display:block;}
  #header.detail .logo{display:none;}
  #header.detail .tab_bar{display:none;}

  #header.detail.gnb_hidden{transform: translateY(-103%) ; transition: 0s all;}

  #header.mypage .btn_back{display:block;}
  #header.mypage .logo{display:none;}
  #header.mypage .tab_bar{display:none;}

  #header .dropdown.search_on .search_box {
    height: calc(100vh - 56px);
    overflow-y: auto;
  }
}
</style>
