<template>
  <div class="tab_bar">
    <ul>
      <li v-for="item in props.list" :key="item.type">
        <button type="button" :class="{ on: item.isActive }" @click="handleClick(item.type)">
          <i :class="item.icon" />
          <span class="blind">{{ item.name }}</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { type INavigatorItem } from '../types/navigator'

const props = withDefaults(
  defineProps<{ list: INavigatorItem[] }>(),
  { list: () => [] }
)

const emit = defineEmits<{(e: 'click', type: string): void}>()

const handleClick = (type: string) => {
  emit('click', type)
}
</script>

<style scoped>
.tab_bar {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--color-background-default);
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
}

.tab_bar ul {
  padding: 1.25rem 1.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .tab_bar {
    display: block;
  }

  .tab_bar i:before {
    color: var(--color-icon-default);
  }

  .tab_bar .on i:before {
    color: var(--color-icon-navigation-enabled);
  }
}
</style>
