import KeywordService from '../services/keyword-service'

export default function useContentSearch () {
  const keywordService = new KeywordService()

  /**
   * 검색 키워드 목록 조회
   * @param keyword 키워드
   * @returns
   */
  const getSearchKeywordList = (keyword: string) => {
    return keywordService.getSearchKeywordList({ keyword: keyword.trim() })
  }

  /**
   * 추천 검색어 목록
   * @returns
   */
  const getRecommendKeywordList = () => {
    return keywordService.getRecommendKeywordList()
  }

  /**
   * 키워드에 하이라이트를 추가해줍니다.
   * 하이라이트는 <mark> 태그로 추가가 됩니다.
   * @param word 하이라이트를 표시할 단어
   * @param keyword 하이라이트 단어
   * @returns 하이라이트가 적용된 HTML 문자열
   */
  const setHighlightKeyword = (word: string, keyword: string): string => {
    const regex = new RegExp(keyword, 'gi')
    const match = word.match(regex)
    if (match) {
      return word.replace(regex, `<strong>${match[0]}</strong>`)
    } else {
      return word
    }
  }

  return {
    getSearchKeywordList,
    getRecommendKeywordList,
    setHighlightKeyword
  }
}
