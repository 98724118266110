import { IApiWithResponse, get } from '@tms/core'
import { RecommendSearchService } from '@tms/search'

import type { IKeywordRequest } from '../types/request'
import type { IKeywordListResponse, IRecommendKeywordListResponse } from '../types/response'

export default class KeywordService {
  private recommendService: RecommendSearchService

  constructor () {
    this.recommendService = RecommendSearchService.getInstance()
  }

  /**
   * 검색 키워드 목록 조회
   * @param req
   * @returns
   */
  async getSearchKeywordList (req: IKeywordRequest): Promise<IKeywordListResponse> {
    const response = await this.recommendService.getRecommendSearchList(req.keyword)
    return response.data
  }

  /**
   * 추천 검색어 목록
   * @param req
   * @returns
   */
  async getRecommendKeywordList (): Promise<IRecommendKeywordListResponse> {
    const response: IApiWithResponse<IRecommendKeywordListResponse> = await get('/board/v1/search/recommend')
    return response.data
  }
}
