<template>
  <div>
    <the-gnb />
    <slot />
    <the-footer />

    <client-only>
      <Teleport to="body">
        <popup-alert ref="popupAlertRef" />
        <popup-confirm ref="popupConfirmRef" />
        <snackbar-box ref="snackbarRef" />
        <loading-spinner />
      </Teleport>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import usePopup from '~/composables/layouts/popup'
import useSnackbar from '~/composables/layouts/snackbar'

const { popupAlertRef, popupConfirmRef, openByAlert, closeByAlert, openByConfirm, closeByConfirm } = usePopup()
const { snackbarRef, success, info } = useSnackbar()

provide('alert', { open: openByAlert, close: closeByAlert })
provide('confirm', { open: openByConfirm, close: closeByConfirm })
provide('snackbar', { success, info })

</script>
