<template>
  <div class="search_top" @click="handleClick">
    <input
      ref="inputRef"
      v-model="input"
      type="text"
      class="it"
      :placeholder="props.placeholder"
      @keyup="keyup"
      @keyup.enter="enter"
    >
    <button v-show="isInput" type="button" class="btn_del" @click="remove">
      <i class="icon_size_20 icon_font_del" />
      <span class="blind">닫기</span>
    </button>
    <button type="button" class="btn_search" @click.stop="search">
      <i class="icon_font_search" />
      <span class="blind">검색</span>
    </button>
  </div>
</template>

<script setup lang="ts">
import PATH from '~/modules/content/config/content-path'

const props = defineProps<{ keyword: string, placeholder: string }>()
const emit = defineEmits<{(e: 'keyup', input: string): void, (e: 'enter'): void, (e: 'enable'): void, (e: 'disable'): void, (e: 'search'): void }>()

const route = useRoute()
const input = ref<string>('')
const inputRef = ref<any>(null)
const isFocus = ref<boolean>(false)
const isInput = computed(() => input.value.length > 0)

onMounted(() => {
  input.value = props.keyword
})

watch(() => props.keyword, (data) => {
  input.value = data
})

watch(() => route.fullPath, () => {
  const path = route.path
  if (path === PATH.SEARCH_CONTENTS || path === PATH.SEARCH_BUSINESS) {
    input.value = route.query.keyword?.toString() ?? ''
  } else {
    input.value = ''
  }

  isFocus.value = false
})

const keyup = (event: Event) => {
  const target = event.target as HTMLInputElement
  emit('keyup', target.value)
}

const enter = () => {
  emit('enter')
}

const remove = () => {
  if (inputRef.value) {
    inputRef.value.focus()

    input.value = ''
    emit('keyup', input.value)
  }
}

const handleClick = () => {
  if (!isFocus.value) {
    enable()
  } else {
    disable()
  }
}

const search = () => {
  emit('search')
}

const getIsFocus = () => {
  return isFocus.value
}

const enable = () => {
  isFocus.value = true
  emit('enable')
}

const disable = () => {
  isFocus.value = false
  inputRef.value.blur()
  emit('disable')
}

defineExpose({
  getIsFocus,
  enable,
  disable
})

</script>

<style scoped>
.search_top {
  position: relative;
  display: flex;
  width: 306px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--color-line);
  border-radius: 4px;
  height: 2.125rem;
  padding: 0 16px;
  box-sizing: border-box;
  transition: all 0.1s;
}

.search_top input {
  border: none;
  width: 100%;
  line-height: 1.625rem;
  height: 1.625rem;
  font-size: var(--font-size-6);
  outline: none;
}

.search_top .btn_del {
  display: none;
  margin-right: 10px;
}

.search_top.search_on .btn_del {
  display: inline-block;
}

</style>
